import Header from '@app/components/Header'
import {
  Box,
  Heading,
  useColorModeValue,
  VStack,
  Text,
  SimpleGrid,
  Image as ChakraImage,
  LinkBox,
  Flex,
  LinkOverlay,
} from '@chakra-ui/react'
import type { GetStaticProps, NextPage } from 'next'
import Image from 'next/image'
import NextLink from 'next/link'

import GetStartedOpenSea from '../public/get_started_opensea.png'
import GetStartedEtherscan from '../public/get_started_etherscan.png'
import { Account as AccountType } from '@app/utils/snipe'
import Account from '@app/components/Account'
import { ethToWei } from '@app/utils/ether'
import Price from '@app/components/Price'
import { NextSeo } from 'next-seo'

const ExampleSnipe = ({
  path,
  children,
  image,
  name,
  accountAddress,
}: React.PropsWithChildren<{
  path: string
  image: string
  name: string
  accountAddress: string
}>) => {
  return (
    <LinkBox as={VStack} spacing="3" alignItems="flex-start" maxWidth="400px">
      <ChakraImage src={image} alt={name} width="100%" borderRadius="2xl" />
      <Flex
        alignItems={{ base: 'flex-start', sm: 'center' }}
        justifyContent="space-between"
        width="100%"
        flexDirection={{ base: 'column', sm: 'row' }}
      >
        <Box>
          <NextLink passHref prefetch={false} href={path}>
            <LinkOverlay lineHeight="0">
              <Text as="span" fontWeight="bold" fontSize="2xl">
                {name}
              </Text>
            </LinkOverlay>
          </NextLink>
        </Box>
        <Box fontSize="lg">
          <Account withLink={false} address={accountAddress} />
        </Box>
      </Flex>
      <Box fontSize="lg">{children}</Box>
    </LinkBox>
  )
}

const Home: NextPage = () => {
  return (
    <>
      <NextSeo
        title="Sniped - NFT Snipe Analysis"
        description="See who you outsniped with your NFT purchase, or understand why you got outsniped."
        canonical="https://sniped.lol"
        openGraph={{
          url: 'https://sniped.lol',
          title: 'Sniped - NFT Snipe Analysis',
          description:
            'See who you outsniped with your NFT purchase, or understand why you got outsniped.',
          images: [
            {
              url: 'https://sniped.lol/logo.png',
              width: 512,
              height: 512,
              alt: 'Sniped Logo',
            },
          ],
          site_name: 'Sniped',
        }}
        twitter={{
          site: '@superseaNFT',
          cardType: 'summary_large_image',
        }}
      />
      <Header />
      <VStack spacing="100px" mt="8">
        <Heading as="h2" textAlign="center" maxWidth="700px">
          See who you outsniped with your NFT purchase, or understand why you
          got outsniped.
        </Heading>
        <VStack
          bg={useColorModeValue('gray.100', 'gray.700')}
          borderRadius="3xl"
          maxWidth="1000px"
          width="100%"
          p="12"
          spacing="6"
        >
          <Heading as="h3" fontSize="3xl" textAlign="center">
            Get Started
          </Heading>
          <Text textAlign="center" fontSize="xl">
            Replace{' '}
            <Text as="span" fontWeight="bold" color="blue.500">
              opensea.io
            </Text>{' '}
            with{' '}
            <Text as="span" fontWeight="bold" color="blue.500">
              sniped.lol
            </Text>{' '}
            when viewing any Ethereum NFT on OpenSea.
          </Text>
          <Box
            overflow="hidden"
            width="100%"
            maxW="800px"
            borderRadius="lg"
            lineHeight="0"
          >
            <Box minWidth="700px" ml={{ base: '-60px', xs: 0 }}>
              <Image
                src={GetStartedOpenSea}
                alt="How to view an asset via OpenSea"
              />
            </Box>
          </Box>
          <Text textAlign="center" fontSize="xl" pt="4" maxWidth="700px">
            Alternatively, replace{' '}
            <Text as="span" fontWeight="bold" color="blue.500">
              etherscan.io
            </Text>{' '}
            with{' '}
            <Text as="span" fontWeight="bold" color="blue.500">
              sniped.lol
            </Text>{' '}
            when viewing a successful or failed purchase transaction on
            Etherscan.
          </Text>
          <Box
            overflow="hidden"
            width="100%"
            borderRadius="lg"
            lineHeight="0"
            maxW="800px"
          >
            <Box minWidth="700px" ml={{ base: '-63px', xs: 0 }}>
              <Image
                src={GetStartedEtherscan}
                alt="How to view an asset via OpenSea"
              />
            </Box>
          </Box>
        </VStack>
        <Box>
          <Heading as="h3" fontSize="3xl" textAlign="center" mb="6">
            Highlighted Snipes
          </Heading>
          <SimpleGrid
            columns={{ base: 1, sm: 2, md: 3 }}
            maxWidth="container.lg"
            spacing="10"
          >
            <ExampleSnipe
              path="/assets/ethereum/0x5755ab845ddeab27e1cfce00cd629b2e135acc3d/4810?tx=aa1835"
              image="https://openseauserdata.com/files/2a6e8eb075aa9f4f1c6234a8cf985a2e.svg"
              name="Larva Lad #4810"
              accountAddress={'0x694367eA528240fec8172581282FF171A6ec39Aa'}
            >
              Outsniped <strong>6 other buyers</strong> to purchase a rare NFT
              that sold for <strong>+1555%</strong> profit{' '}
              <strong>11 hours later</strong>.
            </ExampleSnipe>{' '}
            <ExampleSnipe
              path="/assets/ethereum/0x23581767a106ae21c074b2276d25e5c3e136a68b/6861?tx=a5e3f6"
              image="https://lh3.googleusercontent.com/wUxoQS32edng52viegLj4icWw1NN4na4r5DmaXJmsECqgtcaVWdsUci960N25kntkTV37NoIZNE5pth9iUksH2l9bjCFbgWI-9JQ7A"
              name="Moonbirds #6861"
              accountAddress={'0x036eFf96a00B461bf86CDFB6cF1FbEe08e9e3be6'}
            >
              Spent{' '}
              <Price value={ethToWei(1.16)} fontSize="1em" tokenSymbol="ETH" />{' '}
              in gas to outsnipe <strong>34 other buyers</strong>, who
              collectively ended up spending{' '}
              <Price value={ethToWei(3.71)} fontSize="1em" tokenSymbol="ETH" />{' '}
              in failed transaction fees.
            </ExampleSnipe>
            <ExampleSnipe
              path="/assets/ethereum/0x3A2096754Df385553C4252E5A82DC862e64169Bb/5197?tx=270d5a"
              image="https://lh3.googleusercontent.com/4-KEIAMxpkW3zZfkpHfffM0r5MjC4q4KJNPy3DxMqQsg_mNOmkNaEsJoxvTzrVRHFL6AgrHZqGFZ7Yl1mt5aFxugCi155kzYWUPW"
              name="The Possessed #5197"
              accountAddress={'0x7672c0Ff5b3ECB17f019BEc349BFf79a70B5cBb9'}
            >
              Outsniped <strong>50 other buyers</strong> for a quick flip with{' '}
              <strong>+102%</strong> profit <strong>in just 4 minutes</strong>.
            </ExampleSnipe>
          </SimpleGrid>
        </Box>
      </VStack>
    </>
  )
}

export default Home
